import { defineStore } from 'pinia';
import axios from 'axios';

export const useArticlesStore = defineStore('articles', {
    state: () => ({
        articles: [],
    }),
    actions: {
        async fetchArticles() {
            let res = await axios.get('articles');
            this.$state.articles = res.data;
        },
        clearArticles() {
            this.$reset();
        },
    },
    persist: true,
});
