<template>
    <div class="bg-white">
        <div
            class="container"
            style="padding-bottom: 1.5rem"
            :style="{
                'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            }"
        >
            <div
                @click="router.back()"
                class="d-flex flex-row align-items-center text-primary"
                style="font-weight: 500"
            >
                <button class="back-btn rounded">
                    <i class="fa fa-chevron-left"></i>
                </button>
                {{ t('misc.back') }}
            </div>

            <h1 class="m-0 text-dark">{{ currentArticle.name }}</h1>
        </div>
    </div>
    <div
        v-if="currentArticle"
        :style="{ 'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM }"
        style="background: white"
    >
        <img
            class="cover-image mb-4"
            :src="GLOBAL_VAR_BACKEND_STORAGE_URL + currentArticle.main_image"
        />
        <div
            class="container px-4"
            id="fraola-content"
            style="min-height: 50vh"
        >
            <p v-html="currentArticle.content"></p>
        </div>
    </div>
</template>

<style scoped>
.back-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--bs-primary);
    width: 20px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1rem;
}

.cover-image {
    width: 100%;
    object-fit: cover;
    height: 60vw;
    background-color: white;
}

#fraola-content img {
    max-width: 100% !important;
    object-fit: contain;
}
</style>
<style>
p[data-f-id='pbf'] {
    display: none;
}
</style>

<script setup>
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useArticlesStore } from '@/stores/articles-store';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const route = useRoute();
const router = useRouter();
const articlesStore = useArticlesStore();

articlesStore.fetchArticles();
const { articles } = storeToRefs(articlesStore);

const currentArticle = computed(() => {
    return articles.value.find((article) => article.id == route.params.id);
});
</script>
